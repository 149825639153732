@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

/* Animated Background here */
.particles {
  position: absolute;
  inset: 0;
}

.particle {
  position: absolute;
  left: var(--x);
  top: var(--y);
  width: var(--size);
  height: var(--size);
  background: white;
  border-radius: 50%;
  opacity: var(--opacity);
  animation: float var(--duration) infinite linear;
  animation-delay: var(--delay);
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(100px, 100px);
  }
  50% {
    transform: translate(0, 200px);
  }
  75% {
    transform: translate(-100px, 100px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #4788e2;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #e04b5e;
}

/* Gradient text utility */
.gradient-text {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-[#4788e2] to-[#e04b5e];
}

/* Smooth transitions */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* Card hover effects */
.hover-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.hover-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 10px 30px -5px rgba(0, 0, 0, 0.1);
}

/* Button styles */
.btn-primary {
  @apply bg-[#4788e2] text-white px-6 py-3 rounded-full font-semibold 
         hover:bg-[#f09f36] transition-colors shadow-lg hover:shadow-xl
         transform hover:-translate-y-0.5 active:translate-y-0;
}

/* Form input focus styles */
.form-input:focus {
  @apply ring-2 ring-[#4788e2] outline-none;
}

/* Animation classes */
.fade-in {
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Glass effect */
.glass {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

/* Responsive typography */
@screen md {
  h1 {
    @apply text-5xl;
  }
  h2 {
    @apply text-4xl;
  }
  h3 {
    @apply text-2xl;
  }
}

@screen sm {
  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-3xl;
  }
  h3 {
    @apply text-xl;
  }
}
